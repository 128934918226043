import styled from 'styled-components';

export const MaxContainer = styled.div`
  max-width: 1600px;
  margin: 0 auto;
`;

export const Container = styled.div`
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
  padding: 0 5%;
`;

export const PageWrapper = styled.div`
  display: flex;
  width: 100%;
  position: fixed;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  border: 11px solid #b08f66;
  position: relative;

  ${({color}) => {
    if (color === 'red') {
      return `
        border: 11px solid red;
      `
    }
  }}
`