import RubikRegular from "../fonts/Rubik/Rubik-Regular.ttf";
import RubikMedium from "../fonts/Rubik/Rubik-Medium.ttf";
import RubikBold from "../fonts/Rubik/Rubik-Bold.ttf";
import RobotoMonoRegular from "../fonts/Roboto_Mono/RobotoMono-Regular.ttf";
import RobotoMonoBold from "../fonts/Roboto_Mono/RobotoMono-Bold.ttf";
import RobotoMonoMedium from "../fonts/Roboto_Mono/RobotoMono-Medium.ttf";

export default {
  RubikRegular,
  RubikMedium,
  RubikBold,
  RobotoMonoRegular,
  RobotoMonoMedium,
  RobotoMonoBold
};