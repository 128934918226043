import styled from 'styled-components';

export const Button = styled.button`
  padding: 12px 24px;
  color: #b08f66;
  border: 1px solid #b08f66;
  background-color: white;
  font-family: 'Rubik', sans-serif;
  transition: all .2s ease-in-out;

  &:hover {
    background-color: #b08f66;
    color: white;
  }
`;
