import { createGlobalStyle } from 'styled-components'
import { normalize } from 'polished';
import fontFiles from "./Fonts";

const GlobalStyle = createGlobalStyle`
  ${normalize()}
  
  html {
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  a,
  label,
  select,
  option,
  button {
    cursor: pointer;
  }

  fieldset {
    border: 0;
    margin: 0;
    padding: 0;
  }

  textarea {
    resize: vertical;
  }

  button,
  input[type="button"],
  input[type="submit"],
  input[type="reset"] {
    appearance: none;
    background: transparent;
    border: 0;
    color: inherit;
    font: inherit;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    padding: 0;
    user-select: none;
    width: auto;

    &::-moz-focus-inner {
      border: 0;
      padding: 0;
    }
  }

  p:first-of-type {
    margin-top: 0;
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  img,
  video {
    height: auto;
    max-width: 100%;
  }

  figure {
    margin: 0;
  }

  h1, h2, h3, h4, h5, h6 {
    display: block;
    font-weight: normal;
    margin: 1rem 0;

    &:first-child {
      margin-top: 0;
    }

    a { color: inherit;  }
  }

  body {
    margin: 0;
    padding: 0;
    // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    //   "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    //   sans-serif;
    // -webkit-font-smoothing: antialiased;
    // -moz-osx-font-smoothing: grayscale;
  }

  @font-face {
    font-family: 'Roboto-Mono';
    font-style: normal;
    font-weight: normal;
    src: local("Roboto Mono"), local("Roboto-Mono"), url(${fontFiles.RobotoMonoRegular}) format("truetype");
  }

  @font-face {
    font-family: 'Rubik-Medium';
    font-style: normal;
    font-weight: 500;
    src: local("Rubik Medium"), local("Rubik-Medium"), url(${fontFiles.RubikMedium}) format("truetype");
  }

  h1,h2,h3,h4,h5,h6,p{
    font-family: 'Roboto-Mono', monospace;
  }



`;

export default GlobalStyle;